
import optinmonster from '../../images/comparison-logo-images/optinmonster.png'
import sleeknote from '../../images/comparison-logo-images/sleeknote.png'
import optimonk from '../../images/comparison-logo-images/optimonk.png'
import ninjapopup from '../../images/comparison-logo-images/ninjapopup.png'
import adoric from '../../images/comparison-logo-images/adoric.png'
import mailmunch from '../../images/comparison-logo-images/mailmunch.png'
import poptin from '../../images/comparison-logo-images/poptin.png'
import hellobar from '../../images/comparison-logo-images/hellobar.png'
import privy from '../../images/comparison-logo-images/privy.png'
import sumo from '../../images/comparison-logo-images/sumo.png'


export const comparisonAppsConstants = [
    {
        productName: "OptinMonster",
        image: optinmonster,
        alt: "OptinMonster Logo",
        route: 'optinmonster'
    },
    {
        productName: "Sleeknote",
        image: sleeknote,
        alt: "Sleeknote Logo",
        route: 'sleeknote'
    },
    {
        productName: "Optimonk",
        image: optimonk ,
        alt: "Optimonk Logo",
        route: 'optimonk'
    },
    {
        productName: "Ninja Popup",
        image: ninjapopup,
        alt: "Ninja Popup Logo",
        route: 'ninja-popup'
    },
    {
        productName: "Adoric",
        image: adoric ,
        alt: "Adoric Logo",
        route: 'adoric'
    },
    {
        productName: "Mailmunch",
        image: mailmunch ,
        alt: "Mailmunch Logo",
        route: 'mailmunch'
    },
    {
        productName: "Poptin",
        image: poptin ,
        alt: "Poptin Logo",
        route: 'poptin'
    },
    {
        productName: "Hello Bar",
        image: hellobar ,
        alt: "Hello Bar Logo",
        route: 'hello-bar'
    },
    {
        productName: "Privy",
        image: privy ,
        alt: "Privy Logo",
        route: 'privy'
    },
    {
        productName: "Sumo",
        image: sumo ,
        alt: "Sumo Logo",
        route: 'sumo'
    }
]