import { navigate } from 'gatsby-link'
import React from 'react'

const ComparisonProductCard = ({image, productName, alt, link}) => {
    return (
        <>
            <div onClick={()=> {navigate(`/comparison/${link}`)}} className="px-3 py-4 flex flex-row items-center justify-center space-x-2 xl:space-x-4 bg-white rounded-md hover:shadow-lg transform transition-all hover:scale-105 duration-300 ease-in-out cursor-pointer" style={{border: '1px solid #C4C4C4'}}>
                <img className="w-7 h-7 lg:w-10 lg:h-10" src={image} alt={alt} />
                <span className="text-lg md:text-2xl lg:text-28px text-font_secondary font-medium font-poppins capitalize truncate">{productName}</span>
            </div>
        </>
    )
}

export default ComparisonProductCard
