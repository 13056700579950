import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import Companies from '../../components/Integrations/Companies'
import FAQ from '../../components/Integrations/FAQ'
import HomeReviewBox from '../../components/Integrations/HomeReviewBox'
import IntegrationPopupClients from '../../components/Integrations/IntegrationPopupClient'
import IntegrationPopupTestimonials from '../../components/Integrations/IntegrationPopupTestmonial'
import Layout from '../../components/layout'
import {comparisonAppsConstants} from '../../components/Comparison/ComparisonAppsConstants'
import ComparisonProductCard from '../../components/Comparison/ComparisonProductCard'
import Metatags from '../../components/Metatags'


const Comparison = () => {

    const data = useStaticQuery(graphql`
        query HomeBannerReviewBoxQueryComparison {
            AppsumoImage: file(
                absolutePath: {regex: "/images/integration-pages/"}
                name: {eq: "appsumo-rating"}
                ) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, quality: 100)
                }
            }

            StarGroupImage: file(
                absolutePath: {regex: "/images/integration-pages/"}
                name: {eq: "star-group"}
                ) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, quality: 100)
                }
            }

            UserImage: file(
                absolutePath: {regex: "/images/integration-pages/"}
                name: {eq: "user"}
                ) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, quality: 100)
                }
            }

            EyeImage: file(
                absolutePath: {regex: "/images/integration-pages/"}
                name: {eq: "eye"}
                ) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, quality: 100)
                }
            }

        }
    `)

    const appsumoImage = getImage(data.AppsumoImage)
    const starGroupImage = getImage(data.StarGroupImage)
    const userImage = getImage(data.UserImage)
    const eyeImage = getImage(data.EyeImage)

    return (
        <>         
            <Layout isIntegrationPage={true}>

                <Metatags
                    title="Optinly vs Other Pop-up Builders"
                    description="Compare Optinly with other pop-up builders and discover the unique features that set it apart. Find out why Optinly is the best choice for your pop-up needs.
                    "
                />
            
                {/* HOME BANNER SECTION */}
                <section className="relative w-screen h-700px xs:h-650px md:h-600px home-banner-background">

                    {/* MAIN SECTION */}
                    <div className="w-full pt-10 mx-auto text-center 2xl:w-4/5 lg:pt-24">

                        <div className="py-14 flex flex-col space-y-2.5">
                            <h1 className="text-2xl font-semibold text-center text-primary md:text-28px lg:text-32px font-poppins">Optinly - The Pop-Up Builder You've Been Looking For</h1>
                            <p className="w-4/5 mx-auto text-sm font-normal text-center 2xl:w-3/5 text-font_primary md:text-base lg:text-lg font-roboto"> Optinly is the perfect solution to all your pop-up needs. Bring in more conversions and revenue without having to pay more. Explore what makes Optinly different from other pop-up builders. </p>
                        </div>

                        <div>
                            <a href="https://app.optinly.com/" target="_blank" className="px-6 py-2 text-sm font-semibold text-center rounded-lg text-button_text md:text-base lg:text-lg bg-custom_yellow focus:outline-none hover:text-button_text home-button-shadow">Grow Your  Email List </a>
                        </div>

                    </div>

                    {/* REVIEW SECTION */}
                    <HomeReviewBox
                        appsumoImage={appsumoImage}
                        starGroupImage={starGroupImage}
                        userImage={userImage}
                        eyeImage={eyeImage}
                    />

                </section>
                

                {/* COMPANIES */}
                <Companies /> 

                {/* INTEGRATION LINKS SECTION */}
                <div className="w-full integration-links-grid-background">

                    <div className="w-11/12 pt-16 mx-auto space-y-4 lg:w-full xl:w-4/5">
                        <h2 className="text-xl font-medium text-center text-secondary md:text-2xl font-roboto">See how Optinly fares from features to pricing compared to popular pop-up plugins.</h2>
                        <h4 className="w-full mx-auto text-sm font-normal leading-6 text-center lg:w-4/5 text-secondary md:text-base lg:text-lg md:leading-7 lg:leading-9 font-roboto">From ready-made templates that are perfect for instant campaign setup to integration with all major ESPs while being affordable, check out why Optinly stands out when it comes to pop-up-based lead capture.</h4>
                    </div>
                    
                    <div className="grid w-4/5 grid-cols-1 py-16 mx-auto sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 gap-x-8 gap-y-6">

                        {comparisonAppsConstants.map((product, index)=> {
                            return(
                                <ComparisonProductCard 
                                    key={index} 
                                    productName={product.productName} 
                                    alt={product.productAlt} 
                                    image={product.image} index={index} 
                                    link={product.route}
                                />
                            )
                        })}

                    </div>
                    
                </div>
                

                {/* CUSTOMER REVEIW */}
                <div className="mt-5 xs:mt-16 lg:mt-36">
                    <IntegrationPopupTestimonials />
                </div>

                {/* INTEGRATIONS COMPANIES */}
                <IntegrationPopupClients />

                {/* FAQ */}
                {/* <FAQ /> */}

                {/* FOOTER */}
                {/* <IntegrationFooter /> */}
            </Layout>
        </>
    )
}

export default Comparison
